<template>
  <FormWrapper
    :formTitle="''"
    :formClass="'profile-form'"
    :v="$v.form"
    :formButton="translations('Save')"
    :successMessage="translations('YourProfileUpdated')"
    :formCallback="doProfileEdit"
    @form-success="goFurther"
  >
    <h2>{{ translations("UserProfile") }}</h2>
    <TextField
      v-model="form.firstName"
      :v="$v.form.firstName"
      :fieldLabel="translations('EnterYourFullName')"
    />
    <div class="three-fields">
      <PhoneField
        v-model="form.phone"
        :v="$v.form.phone"
        :fieldLabel="translations('EnterYourPhone')"
        :phoneMask="
          (ListLoggedUser && ListLoggedUser.phoneMask.mask) || phoneMask
        "
        :countryCode="
          (ListLoggedUser && ListLoggedUser.phoneMask.iso_code_2) || 'RU'
        "
        @code-change="codeChange($event)"
      />
      <EmailField
        v-model="form.email"
        :v="$v.form.email"
        :fieldLabel="translations('EnterYourEmail')"
      />
      <TextField
        v-model="form.telegram"
        :v="$v.form.telegram"
        :fieldLabel="translations('EnterYourTelegram')"
      />
    </div>
    <br />
    <SelectField
      v-model="form.actType"
      :allowEmpty="false"
      :items="actTypeItems"
      :fieldLabel="translations('UserActReceiveType')"
    />
    <h2>{{ translations("UserDeliveryAddress") }}</h2>
    <div class="two-fields">
      <SelectField
        v-model="form.country"
        :v="$v.form.country"
        :allowEmpty="false"
        style="z-index: 100"
        :items="allCountriesSelect"
        :fieldLabel="translations('UserDeliveryCountry')"
      />
      <TextField
        v-model="form.region"
        :v="$v.form.region"
        :fieldLabel="translations('UserDeliveryRegion')"
      />
    </div>
    <div class="three-fields">
      <TextField
        v-model="form.city"
        :v="$v.form.city"
        :fieldLabel="translations('UserDeliveryCity')"
      />
      <TextField
        :class="'double-field'"
        v-model="form.address"
        :v="$v.form.address"
        :fieldLabel="translations('YourAddress')"
      />
    </div>
    <div class="three-fields">
      <TextField
        v-model="form.contactindex"
        :v="$v.form.contactindex"
        :fieldLabel="translations('EnterContactIndex')"
      />
      <TextField
        v-model="form.contactname"
        :v="$v.form.contactname"
        :fieldLabel="translations('EnterContactName')"
      />
      <PhoneField
        v-model="form.contactphone"
        :v="$v.form.contactphone"
        :fieldLabel="translations('EnterContactPhone')"
        :phoneMask="
          (ListLoggedUser && ListLoggedUser.contact_phoneMask.mask) || phoneMask
        "
        :countryCode="
          (ListLoggedUser && ListLoggedUser.contact_phoneMask.iso_code_2) ||
          'RU'
        "
        @code-change="contactCodeChange($event)"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { defPhoneMask } from "@/config";
import { mapGetters, mapActions } from "vuex";
import { helpers } from "vuelidate/lib/validators";
import FormWrapper from "@/components/app/forms/FormWrapper";
import PhoneField from "@/components/app/forms/FieldComponents/PhoneField";
import EmailField from "@/components/app/forms/FieldComponents/EmailField";
import TextField from "@/components/app/forms/FieldComponents/TextField";
import SelectField from "@/components/app/forms/FieldComponents/SelectField";
import {
  email,
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const phoneMask = defPhoneMask;
const alphaOnly = (value) => {
  return value.search(/\d/) === -1;
};
const fullphone = (field) => (value, vm) => {
  if (field === "phone") {
    return value.length === vm.phoneMask.length || !helpers.req(value);
  } else if (field === "contactphone") {
    return value.length === vm.contactphoneMask.length || !helpers.req(value);
  }
};

export default {
  name: "UserProfileForm",
  data: () => {
    return {
      form: {
        firstName: "",
        email: "",
        phone: "",
        phoneMask: "",
        actType: { value: 0, text: "" },
        country: { value: 0, text: "" },
        region: "",
        city: "",
        address: "",
        telegram: "",
        contactname: "",
        contactphone: "",
        contactphoneMask: "",
        contactindex: "",
      },
      actTypeItems: [],
    };
  },
  props: {
    userId: Number,
  },
  components: { FormWrapper, EmailField, PhoneField, TextField, SelectField },
  computed: {
    ...mapGetters([
      "translations",
      "isUserLogged",
      "ListLoggedUser",
      "isCountriesLoaded",
      "isCountriesLoading",
      "allCountriesSelect",
      "defCountrySelect",
    ]),
    getPhoneMask() {
      return defPhoneMask;
    },
  },
  watch: {
    "form.firstName"(v) {
      //      this.$nextTick(() => {
      this.form.firstName = String(v).replace(/\d/g, "");
      //      });
    },
    "form.contactname"(v) {
      //      this.$nextTick(() => {
      this.form.contactname = String(v).replace(/\d/g, "");
      //      });
    },
  },
  validations: {
    form: {
      firstName: {
        required,
        alphaOnly,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
      email: { email, required },
      phone: { required, fullphone: fullphone("phone") },
      telegram: { maxLength: maxLength(50) },
      region: { maxLength: maxLength(100) },
      country: { required },
      city: { required, maxLength: maxLength(100) },
      address: { required, maxLength: maxLength(1000) },
      contactname: {
        required,
        maxLength: maxLength(200),
        alphaOnly,
      },
      contactindex: { maxLength: maxLength(50) },
      contactphone: { required, fullphone: fullphone("contactphone") },
    },
  },
  methods: {
    ...mapActions(["profileEdit", "phoneCodeChange", "fetchCountries"]),
    async doProfileEdit(recaptchaToken) {
      console.log("начало отправки редактирования профиля");
      await this.profileEdit({
        id: this.userId,
        firstName: this.form.firstName,
        email: this.form.email,
        phone: this.form.phone,
        telegram: this.form.telegram,
        country: this.form.country,
        region: this.form.region,
        city: this.form.city,
        address: this.form.address,
        actType: this.form.actType.value,
        contactName: this.form.contactname,
        contactIndex: this.form.contactindex,
        contactPhone: this.form.contactphone,
        token: recaptchaToken,
      });
    },
    goFurther() {
      // дальнейшие действия после успешной отправки формы
    },
    async codeChange(value) {
      await this.phoneCodeChange({ phoneField: "phone", code: value });
      this.form.phoneMask = this.ListLoggedUser.phoneMask.mask;
    },
    async contactCodeChange(value) {
      await this.phoneCodeChange({ phoneField: "contact-phone", code: value });
      this.form.contactphoneMask = this.ListLoggedUser.contact_phoneMask.mask;
    },
  },
  async mounted() {
    if (this.isUserLogged) {
      this.form.firstName = this.ListLoggedUser.first_name;
      this.form.lastName = this.ListLoggedUser.last_name;
      this.form.middleName = this.ListLoggedUser.middle_name;
      this.form.phone = this.ListLoggedUser.phone;
      this.form.phoneMask = this.ListLoggedUser.phoneMask.mask || phoneMask;
      this.form.email = this.ListLoggedUser.email;
      this.form.telegram = this.ListLoggedUser.telegram;
      this.form.region = this.ListLoggedUser.region;
      this.form.city = this.ListLoggedUser.city;
      this.form.address = this.ListLoggedUser.address;
      this.form.contactname = this.ListLoggedUser.contact_name;
      this.form.contactphone = this.ListLoggedUser.contact_phone;
      this.form.contactphoneMask =
        this.ListLoggedUser.contact_phoneMask.mask || phoneMask;
      this.form.contactindex = this.ListLoggedUser.contact_index;

      const actType = parseInt(this.ListLoggedUser.act_type);
      this.form.actType = {
        value: actType,
        text:
          actType === 0
            ? this.translations("ActPerMonth")
            : this.translations("ActPerOrder"),
      };
    }
    this.actTypeItems = [
      { value: 0, text: this.translations("ActPerMonth") },
      { value: 1, text: this.translations("ActPerOrder") },
    ];

    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries({
        lang: this.$cookies.get("user_language"),
      });
    }
    if (!this.ListLoggedUser.country_id) {
      this.form.country = this.defCountrySelect;
    } else {
      this.form.country = this.allCountriesSelect.find(
        (country) => country.value === this.ListLoggedUser.country_id
      );
    }
  },
};
</script>