<template>
  <div>
    <UserProfileForm :userId="userId" />
  </div>
</template>

<script>
import UserProfileForm from "@/components/app/forms/account/UserProfileForm";
import { mapGetters } from "vuex";

export default {
  name: "UserProfile",
  components: {
    UserProfileForm,
  },
  data: () => {
    return {
      userId: 0,
    };
  },
  computed: {
    ...mapGetters(["translations", "ListLoggedUser"]),
  },
  mounted() {
    this.userId = this.ListLoggedUser.id;
  },
};
</script>